import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

function App() {
  const [inputAngle, setInputAngle] = useState(68.0);
  const [gannAngles, setGannAngles] = useState([]);
  const [calculationsPerformed, setCalculationsPerformed] = useState(false);

  const calculateGannAngles = (input) => {
    const fixedGannAngles = [82.5, 75, 71.25, 63.75, 45, 26.25, 18.75, 15, 7.5];
    const calculatedAngles = [input];
    let currentAngle = input;

    for (let i = 0; i < fixedGannAngles.length - 1; i++) {
      const difference = fixedGannAngles[i] - fixedGannAngles[i + 1];
      currentAngle -= difference;
      if (currentAngle >= 0) {
        calculatedAngles.push(Number(currentAngle.toFixed(2)));
      } else {
        break;
      }
    }

    return calculatedAngles;
  };

  const handleCalculate = () => {
    const angles = calculateGannAngles(inputAngle);
    setGannAngles(angles);
    setCalculationsPerformed(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
            Gann Angle Progression Analyzer
          </Typography>
          <Typography variant="body1" paragraph>
            Enter an angle in degrees to calculate the corresponding Gann angle progression.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 4 }}>
            <TextField
              type="number"
              label="Enter an angle (in degrees)"
              value={inputAngle}
              onChange={(e) => setInputAngle(Number(e.target.value))}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleCalculate();
                }
              }}
              variant="outlined"
              sx={{ width: '250px' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCalculate}
              sx={{ height: '56px' }}
            >
              Calculate
            </Button>
          </Box>
        </Box>
        {calculationsPerformed && (
          <>
            <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mb: 4, width: '100%' }}>
              <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2' }}>
                Gann Angle Calculations
              </Typography>
              <TableContainer>
                <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Gann Angle 1</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Gann Angle 2</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Difference</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Resulting Angle</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gannAngles.slice(0, -1).map((angle, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{angle}</TableCell>
                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{gannAngles[index + 1]}</TableCell>
                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{(angle - gannAngles[index + 1]).toFixed(2)}</TableCell>
                        <TableCell>{gannAngles[index + 1]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper elevation={3} sx={{ p: 3, borderRadius: 2, width: '100%' }}>
              <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2' }}>
                Explanation
              </Typography>
              <Typography variant="body1" paragraph>
                Gann angles are a trading tool used in technical analysis to measure trends and identify potential support and resistance levels. In this calculation method, we use a fixed set of Gann angles: 82.5, 75, 71.25, 63.75, 45, 26.25, 18.75, 15, 7.5.
              </Typography>
              <Typography variant="body1" paragraph>
                The angles are calculated by following these steps:
              </Typography>
              <ol>
                <li>Start with the user's input angle (currently {inputAngle}°).</li>
                <li>Calculate the differences between consecutive fixed Gann angles.</li>
                <li>Subtract these differences from the input angle sequentially.</li>
                <li>Stop the calculation when a negative angle is encountered.</li>
              </ol>
              <Typography variant="body1" paragraph>
                The table above shows:
              </Typography>
              <ul>
                <li>Gann Angle 1 and Gann Angle 2: The fixed Gann angles used for calculation</li>
                <li>Difference: The difference between consecutive fixed Gann angles</li>
                <li>Resulting Angle: The calculated Gann angle based on the input</li>
              </ul>
              <Typography variant="body1" paragraph>
                This method provides a unique sequence of Gann angles based on the input, which can be used to identify potential trend changes and support/resistance levels in financial markets. The sequence stops when a negative angle would be produced, ensuring that only meaningful, positive angles are displayed and analyzed.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Note on non-negative angles:</strong> We exclude negative angles from the calculations because Gann angles are typically used to analyze upward trends. Negative angles would imply a downward trend, which is not the primary focus of this tool.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>TradingView Tip:</strong> Before applying Gann angles in TradingView, make sure to lock the chart to maintain the correct angle proportions. This ensures that the Gann angles are displayed accurately relative to price and time.
              </Typography>
            </Paper>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default App;
